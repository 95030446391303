import { defineMessage } from "react-intl";

export const ROOM_PICTURES_COPYRIGHT_TYPE_COPYRIGHT = "copyright";
export const ROOM_PICTURES_COPYRIGHT_TYPE_PERSONAL = "personal";
export const ROOM_PICTURES_COPYRIGHT_TYPE_ROYALTY_FREE = "royalty_free";

export const roomPicturesCopyrightTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, copyright {Copyright} personal {Photo personnelle} royalty_free {Photo libre de droit} other {{sort}}}",
});

export const ROOM_PICTURES_COPYRIGHT_TYPE_SORTS = {
  [ROOM_PICTURES_COPYRIGHT_TYPE_COPYRIGHT]: {
    id: ROOM_PICTURES_COPYRIGHT_TYPE_COPYRIGHT,
  },
  [ROOM_PICTURES_COPYRIGHT_TYPE_PERSONAL]: {
    id: ROOM_PICTURES_COPYRIGHT_TYPE_PERSONAL,
  },
  [ROOM_PICTURES_COPYRIGHT_TYPE_ROYALTY_FREE]: {
    id: ROOM_PICTURES_COPYRIGHT_TYPE_ROYALTY_FREE,
  },
};

export const ROOM_PICTURES_COPYRIGHT_TYPE_LIST = Object.values(
  ROOM_PICTURES_COPYRIGHT_TYPE_SORTS,
);
